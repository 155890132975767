import "bootstrap-italia/dist/css/bootstrap-italia.min.css";
import { Container } from "design-react-kit";
import React from "react";
import "typeface-lora";
import "typeface-roboto-mono";
import "typeface-titillium-web";
import "./App.css";
import Body from "./components/Body";
import AppFooter from "./containers/Footer";
import AppHeader from "./containers/Header";

const App = () => {
  const authority = window.Configs?.REACT_APP_AUTHORITY || "Authority";
  const title = window.Configs?.REACT_APP_TITLE || "Title";
  const subtitle = window.Configs?.REACT_APP_SUBTITLE || "Subtitle";

  return (
    <div>
      <AppHeader authority={authority} title={title} subtitle={subtitle} />
      <Container className="px-4 my-4">
        <Body />
      </Container>
      <AppFooter title={title} subtitle={subtitle} />
    </div>
  );
};

export default App;
