import { Alert, Container, Spinner } from "design-react-kit";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

const Body = () => {
  const [body, setBody] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    getBody();
  }, []);

  const getBody = async () => {
    try {
      const response = await fetch("/body.md");
      const body = await response.text();
      setBody(body);
    } catch (error) {
      console.error(error);
      setErrorMessage(error);
    }
  };

  if (body) {
    return (
      <div>
        <Alert color="danger" isOpen={!!errorMessage}>
          {errorMessage}
        </Alert>
        <ReactMarkdown children={body} />
      </div>
    );
  } else {
    return (
      <Container className="text-center p-4">
        <h5 className="m-3">Loading...</h5>
        <Spinner double active />
      </Container>
    );
  }
};

export default Body;
