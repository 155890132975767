import {
  Collapse,
  Header,
  HeaderBrand,
  HeaderContent,
  Headers,
  HeaderToggler,
  Icon,
  Nav,
  NavItem,
  NavLink
} from "design-react-kit";
import React, { useState } from "react";
import logo from "../logo.svg";

const AppHeader = ({ authority, title, subtitle }) => {
  return (
    <Headers>
      <SlimHeader authority={authority} />
      <div className="it-nav-wrapper">
        <CenterHeader title={title} subtitle={subtitle} />
        <NavHeader />
      </div>
    </Headers>
  );
};

const SlimHeader = ({ authority="Authority" }) => {
  return (
    <Header type="slim">
      <HeaderContent>
        <HeaderBrand responsive>{authority}</HeaderBrand>
      </HeaderContent>
    </Header>
  );
};

const CenterHeader = ({ title="Title", subtitle="Subtitle"}) => {
  return (
    <Header type="center">
      <HeaderContent>
        <HeaderBrand iconName={logo}>
          <h2>{title}</h2>
          <h3>{subtitle}</h3>
        </HeaderBrand>
      </HeaderContent>
    </Header>
  );
};

const NavHeader = () => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Header type="navbar">
      <HeaderContent expand="lg">
        <HeaderToggler
          aria-controls="nav1"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setCollapsed(!collapsed)}
        >
          <Icon icon="it-burger" />
        </HeaderToggler>
        <Collapse
          isOpen={!collapsed}
          header
          navbar
          onOverlayClick={() => setCollapsed(!collapsed)}
        >
          <div className="menu-wrapper">
            <Nav navbar>
              <NavItem>
                <NavLink href="#">Home</NavLink>
              </NavItem>
            </Nav>
          </div>
        </Collapse>
      </HeaderContent>
    </Header>
  );
};

export default AppHeader;
