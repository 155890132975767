import { Col, Container, Icon, Row } from "design-react-kit";
import React from "react";
import logo from "../logo.svg";

const AppFooter = ({ title = "Title", subtitle = "Subtitle" }) => {
  return (
    <footer className="it-footer">
      <div className="it-footer-main">
        <Container>
          <section>
            <Row className="clearfix">
              <Col sm={12}>
                <div className="it-brand-wrapper">
                  <a href="#">
                    <Icon icon={logo} />
                    <div className="it-brand-text">
                      <h2>{title}</h2>
                      <h3 className="d-none d-md-block">{subtitle}</h3>
                    </div>
                  </a>
                </div>
              </Col>
            </Row>
          </section>
        </Container>
      </div>
      <div className="it-footer-small-prints clearfix">
        <Container>
          <h3 className="sr-only">Sezione Link Utili</h3>
          <ul className="it-footer-small-prints-list list-inline mb-0 d-flex flex-column flex-md-row">
            <li className="list-inline-item">
              <a href="informativa_privacy.pdf" title="Privacy-policy">
                Privacy policy
              </a>
            </li>
          </ul>
        </Container>
      </div>
    </footer>
  );
};

export default AppFooter;
